/* eslint-disable no-underscore-dangle */

import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Requester from '../helpers/requester';
import DefaultForm from '../formComponents/DefaultForm';
import DefaultInput from '../formComponents/DefaultInput';

/**
 * @prop btnClasses - classes to customize button
 */
export default class LoginModal extends DefaultForm {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hideErrors: true,
      loading: false,
    };

    this.emailInput = React.createRef();
  }

  _openModal = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      hideErrors: true,
    });
  }

  _getToken = () => {
    const tokenEl = document.getElementsByName('csrf-token')[0];
    if (tokenEl) {
      return tokenEl.getAttribute('content');
    }
    return '';
  }

  _handleKeydown = (e) => {
    if (e.keyCode === 13) {
      this._handleLogin();
    }
  }

  _handleLogin = () => {
    this.setState({
      hideErrors: true,
      loading: true,
    });

    const success = (response) => {
      window.location = `/organizations/${response.data.organization_id}`;
    };
    const failure = () => {
      this.setState({
        hideErrors: false,
        loading: false,
      });
    };
    Requester.post(this.props.createSessionPath, this._formFields(), success, failure);
  }

  render() {
    let loadingContainer;

    if (this.state.loading) {
      loadingContainer = (
        <div className="loading-container">
          <div className="loading" />
        </div>
      );
    }

    return (
      <div>
        <button
          data-cy="login-button"
          type="button"
          className={this.props.btnClasses}
          onClick={this._openModal}
        >
          log in
        </button>
        <Modal
          data-cy="login-modal"
          role="button"
          bssize="small"
          aria-labelledby="login-title"
          className="login-modal"
          show={this.state.showModal}
          onHide={this._closeModal}
          onEntered={() => { this.emailInput.current.focus(); }}
          style={{
            /* Manually override problematic opacity styling */
            opacity: `${this.state.showModal ? 1 : 0}`,
          }}
        >
          { loadingContainer }
          <Modal.Header>
            <Modal.Title id="login-title">Log In</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DefaultInput
              label="Email Address"
              id="email-input"
              name="email"
              type="email"
              placeholder="example@email.com"
              onChange={this._handleChange}
              onKeyDown={this._handleKeydown}
              containerClass="mb-3"
              inputRef={this.emailInput}
            />

            <DefaultInput
              label="Password"
              id="password-input"
              name="password"
              type="password"
              onChange={this._handleChange}
              onKeyDown={this._handleKeydown}
            />

            <input
              type="hidden"
              name="authenticity_token"
              value={this._getToken()}
            />

            <div
              hidden={this.state.hideErrors}
              className="marginTop-xs login-error-text"
            >
              The email or password you entered doesn&apos;t match our records.
              Please double check and try again!
            </div>
          </Modal.Body>
          <Modal.Footer>
            <a
              className="button button--text-black pull-left"
              href="/users/password/new"
            >
              Forgot Password?
            </a>
            <button
              type="button"
              className="button button--text-alert"
              onClick={this._closeModal}
            >
              Close
            </button>
            <button
              type="button"
              className="button marginLeft-xs"
              onClick={this._handleLogin}
            >
              Log In
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

LoginModal.propTypes = { btnClasses: PropTypes.string };
LoginModal.defaultProps = { btnClasses: 'button--white' };
