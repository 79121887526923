import React from 'react';
import ReactDOM from 'react-dom';

import '../stylesheets/custom.scss';
import 'bootstrap/scss/bootstrap-utilities.scss';

import NewsletterModal from '../src/components/newsletterModal/NewsletterModal';
import LoginModal from '../src/authentication/LoginModal';
import SignupModal from '../src/authentication/SignupModal';

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.getElementsByClassName('newsletter-modal-container');
  Array.from(containers).forEach((container) => {
    ReactDOM.render(
      <NewsletterModal />,
      container.appendChild(document.createElement('div')),
    );
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.getElementsByClassName('login-modal-container');
  Array.from(containers).forEach((container) => {
    ReactDOM.render(
      <LoginModal
        btnClasses="header-link-item"
        createSessionPath="/api/sessions"
      />,
      container.appendChild(document.createElement('div')),
    );
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.getElementsByClassName('signup-modal-container');
  Array.from(containers).forEach((container) => {
    ReactDOM.render(
      <SignupModal
        btnClass="header-link-item"
        btnText="sign up"
      />,
      container.appendChild(document.createElement('div')),
    );
  });
});
