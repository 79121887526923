/* eslint-disable no-underscore-dangle */

import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Requester from '../../helpers/requester';
import { validateEmail } from '../../helpers/validationHelpers';

import DefaultInput from '../../formComponents/DefaultInput';
import DefaultForm from '../../formComponents/DefaultForm';

import './NewsletterModal.scss';

export default class NewsletterModal extends DefaultForm {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      subscribed: false,
      hideErrors: true,
      loading: false,
    };
  }

  _openModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
      subscribed: false,
    });
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      hideErrors: true,
    });
  }

  _handleKeydown = (e) => {
    if (e.keyCode === 13) {
      this._handleSubscribe();
    }
  }

  _handleSubscribe = () => {
    if (validateEmail(this._formFields().email)) {
      this.setState({
        hideErrors: true,
        loading: true,
      });

      const success = () => {
        this.setState({
          subscribed: true,
          loading: false,
        });
      };

      // Requester will always return success because it is a sidekiq job and
      // cannot get a response from the Mailchimp API
      Requester.post(
        "/api/newsletters/subscribe_to_newsletter",
        this._formFields(),
        success,
      );
    } else {
      this.setState({ hideErrors: false });
    }
  }

  render() {
    const {
      subscribed,
    } = this.state;

    let loadingContainer;

    if (this.state.loading) {
      loadingContainer = (
        <div className="loading-container">
          <div className="loading" />
        </div>
      );
    }

    const modalTitle = subscribed
      ? 'Thanks for Subscribing'
      : 'Subscribe to Replate';

    return (
      <div className="newsletter-modal">
        <button
          data-cy="newsletter-button"
          type="button"
          className="subscribe-button--header"
          onClick={this._openModal}
        >
          <img
            src="https://replate-storage.s3.us-west-1.amazonaws.com/assets/static_pages/home/mail-envelope.svg"
            alt="Subscribe to the Replate Newsletter."
            className="h-50"
          />
        </button>
        <Modal
          role="button"
          aria-labelledby="newsletter-title"
          show={this.state.showModal}
          onHide={this._closeModal}
          onEntered={() => { document.getElementById('email-input').focus(); }}
          style={{
            /* Manually override problematic opacity styling */
            opacity: `${this.state.showModal ? 1 : 0}`,
          }}
        >
          { loadingContainer }
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          {
            subscribed
              ? (
                <div>
                  <Modal.Body>
                    <p className="mt-3">
                      You're all set! Look out for us in your Inbox &#128522;
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="button ms-xs"
                      onClick={this._closeModal}
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </div>
              )
              : (
                <div>
                  <Modal.Body>
                    <DefaultInput
                      label="Email Address"
                      id="email-input"
                      name="email"
                      type="email"
                      placeholder="example@email.com"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                      containerClass="mb-3"
                    />

                    <DefaultInput
                      label="First Name"
                      containerClass="mb-3"
                      name="first_name"
                      id="first_name"
                      type="text"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                    />

                    <DefaultInput
                      label="Last Name"
                      id="last-name-input"
                      name="last_name"
                      type="text"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                    />

                    <div
                      hidden={this.state.hideErrors}
                      className="mt-xs login-error-text"
                    >
                      The email you entered was not valid.
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="button button--text-alert"
                      onClick={this._closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="button ms-xs"
                      onClick={this._handleSubscribe}
                    >
                      Subscribe
                    </button>
                    <div class="newsletter-agree-tos">
                      By signing up you agree to our <a href="/terms">Terms of Use and Privacy Policy</a>.
                    </div>
                  </Modal.Footer>
                </div>
              )
          }
        </Modal>
      </div>
    );
  }
}
