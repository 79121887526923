import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * @prop btnClass - classes to customize button
 * @prop btnText - text to place inside button. Default "Sign Up"
 */
export default class SignupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  open = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  close = () => this.setState({ showModal: false });

  render() {
    const { showModal } = this.state;
    const { btnText, btnClass } = this.props;

    return (
      <div>
        <button
          type="button"
          className={btnClass}
          href=""
          onClick={this.open}
        >
          {btnText}
        </button>
        <Modal
          bssize="small"
          show={showModal}
          className="signup-modal"
          onHide={this.close}
          style={{
            /* Manually override problematic opacity styling */
            opacity: `${showModal ? 1 : 0}`,
          }}
        >
          <Modal.Body>
            <div className="title-container">
              <h1 className="h2">Sign Up</h1>
            </div>

            <a href="/signup?user-type=business">
              <div
                className="signup-row business-row"
              >
                <div className="arrow-row">
                  <h2 className="signup-title">Donate Food</h2>
                  <div className="arrow-icon arrow--green" />
                </div>
                <p className="signup-desc">I&apos;m a Business</p>
              </div>
            </a>

            <a href="/signup?user-type=recipient">
              <div
                className="signup-row recipient-row"
              >
                <div className="arrow-row">
                  <h2 className="signup-title">Receive Food Donations</h2>
                  <div className="arrow-icon arrow--blue" />
                </div>
                <p className="signup-desc">I&apos;m a Nonprofit Organization</p>
              </div>
            </a>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

SignupModal.propTypes = {
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
};
SignupModal.defaultProps = {
  btnClass: '',
  btnText: 'Sign Up',
};
